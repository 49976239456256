import { getValueBasedOnEnv } from "../../../../shared/src/getValueBasedOnEnv";

const productionConfig = {
  apiKey: "AIzaSyDvam26EXm6bpahl6dK45EG6ir4WfcEyAA",
  authDomain: "upcart-ce87f.firebaseapp.com",
  projectId: "upcart-ce87f",
  storageBucket: "upcart-ce87f.appspot.com",
  messagingSenderId: "303734589999",
  appId: "1:303734589999:web:80a9ea5b4192da7e7714da",
  measurementId: "G-K3VWST143Y",
};

const stagingConfig = {
  apiKey: "AIzaSyDhtOvhdi3sDO8KfL8nKWQNKfWNg98iAdk",
  authDomain: "upcart-staging.firebaseapp.com",
  projectId: "upcart-staging",
  storageBucket: "upcart-staging.appspot.com",
  messagingSenderId: "710607680390",
  appId: "1:710607680390:web:b2d9bec3896e0bd266b207",
};

const devConfig = {
  apiKey: "AIzaSyAkheBoStLgFKFlCH0EWNsSxuKgpoq1gmc",
  authDomain: "upcart-dev.firebaseapp.com",
  projectId: "upcart-dev",
  storageBucket: "upcart-dev.appspot.com",
  messagingSenderId: "1038085846412",
  appId: "1:1038085846412:web:0a82ca4c2662f0db8f92a9",
};

export function getFirebaseConfig() {
  return getValueBasedOnEnv({
    dev: devConfig,
    staging: stagingConfig,
    production: productionConfig,
  });
}
