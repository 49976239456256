import { useEffect, useState } from "react";
import styles from "./Toast.module.css";

const Toast = ({
  message,
  isError,
  onDismiss,
  durationToShowInMs,
}: ToastProps) => {
  const [bottom, setBottom] = useState(-70);
  const timeout = durationToShowInMs === undefined ? 3000 : durationToShowInMs;
  useEffect(() => {
    let cancelled = false;
    setBottom(30);
    setTimeout(() => {
      if (cancelled) return;
      setBottom(-70);
    }, timeout);
    return () => {
      cancelled = true;
    };
  }, [message]);
  return (
    <div
      className={[
        styles.container,
        isError ? styles.errorContainer : styles.normalContainer,
      ].join(" ")}
      style={{ bottom: bottom + "px" }}
    >
      <div
        className={[
          styles.message,
          isError ? styles.messageError : styles.messageNormal,
        ].join(" ")}
      >
        {message}
      </div>
      <div
        className={[
          styles.verticalLine,
          isError ? styles.lineError : styles.lineNormal,
        ].join(" ")}
      ></div>
      <div
        className={[
          styles.closeButtonContainer,
          isError ? "" : styles.normalCloseButton,
        ].join(" ")}
        onClick={onDismiss}
      >
        {isError ? <CloseButtonError /> : <CloseButtonNormal />}
      </div>
    </div>
  );
};

const CloseButtonNormal = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4138 9.99997L17.7069 3.70697C17.8944 3.51946 17.9997 3.26515 17.9997 2.99997C17.9997 2.73479 17.8944 2.48048 17.7069 2.29297C17.5193 2.10546 17.265 2.00012 16.9998 2.00012C16.7347 2.00012 16.4804 2.10546 16.2928 2.29297L9.99985 8.58597L3.70685 2.29297C3.614 2.20013 3.50378 2.12648 3.38247 2.07623C3.26117 2.02598 3.13115 2.00012 2.99985 2.00012C2.86855 2.00012 2.73853 2.02598 2.61722 2.07623C2.49592 2.12648 2.38569 2.20013 2.29285 2.29297C2.10534 2.48048 2 2.73479 2 2.99997C2 3.26515 2.10534 3.51946 2.29285 3.70697L8.58585 9.99997L2.29285 16.293C2.10534 16.4805 2 16.7348 2 17C2 17.2651 2.10534 17.5195 2.29285 17.707C2.48036 17.8945 2.73467 17.9998 2.99985 17.9998C3.26503 17.9998 3.51934 17.8945 3.70685 17.707L9.99985 11.414L16.2928 17.707C16.3855 17.8002 16.4957 17.8741 16.617 17.9246C16.7383 17.975 16.8684 18.001 16.9998 18.001C17.1313 18.001 17.2614 17.975 17.3827 17.9246C17.504 17.8741 17.6142 17.8002 17.7069 17.707C17.7998 17.6142 17.8735 17.504 17.9238 17.3827C17.9742 17.2613 18 17.1313 18 17C18 16.8686 17.9742 16.7386 17.9238 16.6173C17.8735 16.496 17.7998 16.3858 17.7069 16.293L11.4138 9.99997Z"
        fill="#5C5F62"
      />
    </svg>
  );
};

const CloseButtonError = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4138 9.99997L17.7069 3.70697C17.8944 3.51946 17.9997 3.26515 17.9997 2.99997C17.9997 2.73479 17.8944 2.48048 17.7069 2.29297C17.5193 2.10546 17.265 2.00012 16.9998 2.00012C16.7347 2.00012 16.4804 2.10546 16.2928 2.29297L9.99985 8.58597L3.70685 2.29297C3.614 2.20013 3.50378 2.12648 3.38247 2.07623C3.26117 2.02598 3.13115 2.00012 2.99985 2.00012C2.86855 2.00012 2.73853 2.02598 2.61722 2.07623C2.49592 2.12648 2.38569 2.20013 2.29285 2.29297C2.10534 2.48048 2 2.73479 2 2.99997C2 3.26515 2.10534 3.51946 2.29285 3.70697L8.58585 9.99997L2.29285 16.293C2.10534 16.4805 2 16.7348 2 17C2 17.2651 2.10534 17.5195 2.29285 17.707C2.48036 17.8945 2.73467 17.9998 2.99985 17.9998C3.26503 17.9998 3.51934 17.8945 3.70685 17.707L9.99985 11.414L16.2928 17.707C16.3855 17.8002 16.4957 17.8741 16.617 17.9246C16.7383 17.975 16.8684 18.001 16.9998 18.001C17.1313 18.001 17.2614 17.975 17.3827 17.9246C17.504 17.8741 17.6142 17.8002 17.7069 17.707C17.7998 17.6142 17.8735 17.504 17.9238 17.3827C17.9742 17.2613 18 17.1313 18 17C18 16.8686 17.9742 16.7386 17.9238 16.6173C17.8735 16.496 17.7998 16.3858 17.7069 16.293L11.4138 9.99997Z"
        fill="white"
      />
    </svg>
  );
};

export interface ToastProps {
  message: string;
  isError: boolean;
  onDismiss: () => void;
  durationToShowInMs?: number;
}

export default Toast;
