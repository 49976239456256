import "@shopify/polaris/dist/styles.css";
import "styles/globals.css";
import "react-vis/dist/style.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { AppProps } from "next/app";
import { usePageIsLoading } from "components/LoadingScreen/usePageIsLoading";
import LoadingScreen from "components/LoadingScreen";
import { FirebaseProvider } from "core/firebase/providers";
import { ShopifyApiProviderWithCache } from "core/shopifyApi/apiProvider";
import {
  LoggedInState,
  useLoggedInState,
} from "core/firebase/hooks/useIsLoggedIn";
import { useRouter } from "next/router";
import Head from "next/head";
import { CustomIntercomProvider } from "components/Intercom";

export const routes = {
  builderNew: "/builder/new",
  builderEditBase: "/builder/",
  offers: "/offers",
  settings: "/settings",
  dashboard: "/",
  login: "/login",
  privacy: "/privacy",
  previewRedirectBase: "/preview/",
};

const unauthenticatedRoutes = [routes.privacy];

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AppProvider i18n={enTranslations}>
      <FirebaseProvider>
        <ShopifyApiProviderWithCache>
          <LoadingWrapper>
            <CustomIntercomProvider>
              <Head>
                <title>UpCart</title>
              </Head>
              <Component {...pageProps} />
            </CustomIntercomProvider>
          </LoadingWrapper>
        </ShopifyApiProviderWithCache>
      </FirebaseProvider>
    </AppProvider>
  );
}

const LoadingWrapper = ({ children }: { children: React.ReactNode }) => {
  const pageIsLoading = usePageIsLoading();
  const loggedInState = useLoggedInState();
  const router = useRouter();
  if (
    pageIsLoading ||
    loggedInState === LoggedInState.uninitialized ||
    router.query.token !== undefined ||
    router.query.shop !== undefined
  ) {
    return <LoadingScreen />;
  }
  return <>{children}</>;
};

export function routeIsUnauthenticated({
  pathname,
}: {
  pathname: string;
}): boolean {
  return (
    unauthenticatedRoutes.findIndex((routename) => routename === pathname) !==
    -1
  );
}

export default MyApp;
