import styles from "./LoadingScreen.module.css";

const LoadingScreen = ({ message }: LoadingScreenProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.outerCircle}>
        <div className={styles.innerCircleContainer}>
          <div className={styles.innerCircle} />
        </div>
      </div>
      {message && <h2 className={styles.message}>{message}</h2>}
    </div>
  );
};

interface LoadingScreenProps {
  message?: string;
}

export default LoadingScreen;
