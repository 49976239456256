import { ShopifyShopInfo as ShopInfo } from "core/api/shopInfo/ShopifyShopInfoFetcher";
import { useEffect, useState } from "react";
import { useCurrentUser } from "../../core/firebase/hooks/useCurrentUser";

export function useShopInfo(): {
  isLoading: boolean;
  shopInfo: ShopInfo | null;
  hasError: boolean;
} {
  const [shopInfo, setShopInfo] = useState<ShopInfo | null>(shopInfoCache.get);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const shopId = useCurrentUser()?.uid;

  useEffect(() => {
    shopInfoCache.set(shopInfo);
  }, [shopInfo]);

  useEffect(() => {
    if (!shopId) return;
    let cancelled = false;
    getShopInfo({ shopId })
      .then((newShopInfo) => {
        if (cancelled) return;
        setShopInfo(newShopInfo);
      })
      .catch((e) => {
        console.error(e);
        if (cancelled) return;
        setHasError(true);
      })
      .finally(() => {
        if (cancelled) return;
        setIsLoading(false);
      });
    return () => {
      cancelled = true;
    };
  }, [shopId]);

  return { isLoading, shopInfo, hasError };
}

async function getShopInfo({ shopId }: { shopId: string }): Promise<ShopInfo> {
  return fetch(`/api/shopInfo?shop=${shopId}`)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    });
}

const shopInfoKey = "shop_info";

const shopInfoCache = {
  get: () => {
    const shopInfoString = localStorage.getItem(shopInfoKey);
    if (!shopInfoString) {
      return null;
    }
    return JSON.parse(shopInfoString);
  },
  set: (newShopInfo: ShopInfo | null) => {
    if (newShopInfo) {
      localStorage.setItem(shopInfoKey, JSON.stringify(newShopInfo));
    } else {
      localStorage.removeItem(shopInfoKey);
    }
  },
};
