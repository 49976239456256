import { useCurrentUser } from "core/firebase/hooks/useCurrentUser";
import { useFirestore } from "core/firebase/hooks/useFirestore";
import { Resource } from "core/resources";
import React, {
  useContext,
  useEffect,
  useState,
  createContext,
  useCallback,
} from "react";
import {
  addResourcesToImmCache,
  createImmShopifyCache,
  ImmShopifyCache,
} from "../cache/shopify/immCache";

const CacheContext = createContext<ImmShopifyCache | null>(null);
const AddToCacheContext = createContext<AddToCacheFn | null>(null);

export function useAddToCache() {
  return useContext(AddToCacheContext);
}

export function useCache() {
  return useContext(CacheContext);
}

export const CacheProvider = ({ children }: { children: React.ReactNode }) => {
  const [cache, setCache] = useState<ImmShopifyCache | null>(null);
  const firestore = useFirestore();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (!firestore || !currentUser) return;
    setCache(createImmShopifyCache());
  }, [firestore, currentUser]);

  const handleCacheAdd = useCallback(
    (resources: Resource[]) => {
      setCache((cache) => {
        if (!cache) return null;
        if (resources.length === 0) return cache;
        return addResourcesToImmCache(resources, cache);
      });
    },
    [addResourcesToImmCache]
  );

  return (
    <CacheContext.Provider value={cache}>
      <AddToCacheContext.Provider value={handleCacheAdd}>
        {children}
      </AddToCacheContext.Provider>
    </CacheContext.Provider>
  );
};

type AddToCacheFn = (resources: Resource[]) => void;
