import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";

export function useLoggedInState() {
  const auth = useAuth();
  const [loggedInState, setLoggedInState] = useState<LoggedInState>(
    LoggedInState.uninitialized
  );

  useEffect(() => {
    if (!auth) return;
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        if (user === null) {
          setLoggedInState(LoggedInState.notLoggedIn);
        } else {
          setLoggedInState(LoggedInState.loggedIn);
        }
      },
      (e) => {
        setLoggedInState(LoggedInState.notLoggedIn);
        console.error(e);
      }
    );
    return unsubscribe;
  }, [auth]);

  return loggedInState;
}

export enum LoggedInState {
  uninitialized = "LoggedInState:uninitialized",
  notLoggedIn = "LoggedInState:notLoggedIn",
  loggedIn = "LoggedInState:loggedIn",
}
