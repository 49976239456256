import {
  addToImmCache,
  createImmCache,
  getAllFromImmCache,
  getFromImmCache,
  ImmCache,
  ImmCacheResult,
} from "../base/immCache";
import {
  getProductKeys,
  getProductKey,
  getCollectionKeys,
  getCollectionKey,
  getCollectionIdsFromResourceIds,
  getProductIdsFromResourceIds,
  getResourceIdsFromKeys,
} from "./keyTransformers";
import { Collection, Product, ResourceId, ResourceType } from "../../types";
import { Resource } from "core/resources";

export function createImmShopifyCache() {
  return createImmCache<ImmShopifyCacheVals>();
}

// PRODUCTS
export function addProductsToImmCache(
  products: Product[],
  cache: ImmShopifyCache
): ImmShopifyCache {
  const kvPairs = products.map((product) => {
    return { key: getProductKey(product.id), val: product };
  });
  return addToImmCache(kvPairs, cache);
}

export function getProductsFromImmCache(
  productIds: string[],
  cache: ImmShopifyCache
): ImmCacheResult<string, Product> {
  return getFromImmCache<Product>(
    getProductKeys(productIds),
    cache as ImmCache<Product>
  );
}

// COLLECTIONS
export function addCollectionsToImmCache(
  collections: Collection[],
  cache: ImmShopifyCache
): ImmShopifyCache {
  const kvPairs = collections.map((coll) => {
    return { key: getCollectionKey(coll.id), val: coll };
  });
  return addToImmCache(kvPairs, cache);
}

export function getCollectionsFromImmCache(
  collectionIds: string[],
  cache: ImmShopifyCache
): ImmCacheResult<string, Collection> {
  return getFromImmCache<Collection>(
    getCollectionKeys(collectionIds),
    cache as ImmCache<Collection>
  );
}

// RESOURCES
function getCollectionsFromResources(resources: Resource[]): Collection[] {
  return resources
    .filter((res) => res.type === ResourceType.collection)
    .map((res) => res as Collection);
}

function getProductsFromResources(resources: Resource[]): Product[] {
  return resources
    .filter((res) => res.type === ResourceType.product)
    .map((res) => res as Product);
}

export function addResourcesToImmCache(
  resources: Resource[],
  cache: ImmShopifyCache
) {
  const collections = getCollectionsFromResources(resources);
  const products = getProductsFromResources(resources);
  const tempCacheWithCollections = addCollectionsToImmCache(collections, cache);
  return addProductsToImmCache(products, tempCacheWithCollections);
}

export function getResourcesFromImmCache(
  resourceIds: ResourceId[],
  cache: ImmShopifyCache
): ImmCacheResult<ResourceId, Resource> {
  const collectionIds = getCollectionIdsFromResourceIds(resourceIds);
  const productIds = getProductIdsFromResourceIds(resourceIds);
  const {
    cachedVals: collections,
    keysOfMissingVals: missingCollections,
  } = getCollectionsFromImmCache(collectionIds, cache);
  const {
    cachedVals: products,
    keysOfMissingVals: missingProducts,
  } = getProductsFromImmCache(productIds, cache);
  return {
    cachedVals: [...collections, ...products],
    keysOfMissingVals: getResourceIdsFromKeys([
      ...missingCollections,
      ...missingProducts,
    ]),
  };
}

export function getAllResourcesFromImmCache(
  cache: ImmShopifyCache
): Resource[] {
  const allCachedKvPairs = getAllFromImmCache(cache);
  return allCachedKvPairs.map((kvPair) => kvPair.val);
}

// TYPES
type ImmShopifyCacheVals = Product | Collection;
export type ImmShopifyCache = ImmCache<ImmShopifyCacheVals>;
