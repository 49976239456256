import { Resource } from "core/resources";
import { ResourceId } from "core/types";
import React, { useContext, createContext } from "react";
import { useAddToCache, useCache } from "./cacheProvider";
import { CacheProvider } from "./cacheProvider";
import * as ShopifyApiImpl from "./shopifyApi";

const ShopifyApiContext = createContext<ShopifyApi | null>(null);

export function useShopifyApi() {
  return useContext(ShopifyApiContext);
}

export const ShopifyApiProviderWithCache = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <CacheProvider>
      <ShopifyApiProvider>{children}</ShopifyApiProvider>
    </CacheProvider>
  );
};

const ShopifyApiProvider = ({ children }: { children: React.ReactNode }) => {
  const cache = useCache();
  const addToCache = useAddToCache();

  async function getSpecificResources(
    resourceIds: ResourceId[]
  ): Promise<Resource[]> {
    const resources = await ShopifyApiImpl.getResources(resourceIds, cache);
    if (addToCache) {
      addToCache(resources);
    }
    return resources;
  }

  function getAllCachedResources(): Resource[] {
    return ShopifyApiImpl.getAllCachedResources(cache);
  }

  return (
    <ShopifyApiContext.Provider
      value={{ getSpecificResources, getAllCachedResources }}
    >
      {children}
    </ShopifyApiContext.Provider>
  );
};

export interface ShopifyApi {
  getSpecificResources: (resourceIds: ResourceId[]) => Promise<Resource[]>;
  getAllCachedResources: () => Resource[];
}
