import firebase from "firebase/app";

export async function login(token: string, auth: firebase.auth.Auth) {
  return auth
    .signInWithCustomToken(token)
    .then()
    .catch((e) => {
      console.log("error", e);
      throw new Error(`Error signing in with token ${token}.`);
    });
}
