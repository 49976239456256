import { FC, useEffect } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useRouter } from "next/router";
import { useShopInfo } from "components/Scaffold/useShopInfo";
import { useShopId } from "core/firebase/hooks/useShopId";
import { routeIsUnauthenticated } from "pages/_app";

const INTERCOM_APP_ID = "wqw8tviu";

export const CustomIntercomProvider: FC = ({ children }) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <IntercomInitializer />
      {children}
    </IntercomProvider>
  );
};

const IntercomInitializer = () => {
  const router = useRouter();
  const { shopInfo } = useShopInfo();
  const shopId = useShopId();
  const { boot, show, showNewMessages } = useIntercom();

  const { isReady: routerIsReady, query, pathname } = router;

  useEffect(() => {
    if (!routerIsReady) return;
    if ((!shopInfo || !shopId) && !routeIsUnauthenticated({ pathname })) return;

    if (shopId) {
      boot({
        name: shopInfo?.shopOwner,
        email: shopInfo?.email,
        userId: shopId,
        customAttributes: {
          shopify_plan_name: shopInfo?.plan_display_name,
        },
      });
    } else {
      // unauthenticated user on public page
      boot();
    }

    // open intercom if url has ?help then open intercom
    // prefill value if ?help has a value (?help={{message}})
    // e.g. ?help=Help%20me%20please then intercom will
    // open a message prefilled with "Help me please"
    const { help: helpQuery } = query;
    if (typeof helpQuery === "string") {
      if (helpQuery.length > 0) {
        showNewMessages(helpQuery);
      } else {
        show();
      }
    }
  }, [routerIsReady, query, pathname, shopInfo, shopId]);

  return null;
};
