export function createImmCache<T>(): ImmCache<T> {
  return {};
}

export function addToImmCache<T>(
  items: KV<T>[],
  cache: ImmCache<T>
): ImmCache<T> {
  const newCache: ImmCache<T> = { ...cache };
  let isDifferent = false;
  for (const { key, val } of items) {
    if (cache[key] !== val) {
      isDifferent = true;
    }
    newCache[key] = makeDeepCopy(val);
  }
  if (!isDifferent) {
    return cache;
  }
  return newCache;
}

export function getFromImmCache<T>(
  keys: string[],
  cache: ImmCache<T>
): ImmCacheResult<string, T> {
  const keysOfMissingVals: string[] = [];
  const cachedVals: T[] = [];
  for (const key of keys) {
    const item = cache[key];
    if (item === undefined) {
      keysOfMissingVals.push(key);
    } else {
      cachedVals.push(item);
    }
  }
  return { cachedVals, keysOfMissingVals };
}

export function getAllFromImmCache<T>(cache: ImmCache<T>): KV<T>[] {
  return Object.entries(cache).map(([key, val]) => {
    return {
      key,
      val,
    };
  });
}

function makeDeepCopy(val: any) {
  return JSON.parse(JSON.stringify(val));
}

export interface ImmCache<V> {
  [key: string]: V;
}

export interface ImmCacheResult<K, V> {
  cachedVals: V[];
  keysOfMissingVals: K[];
}

interface KV<T> {
  key: string;
  val: T;
}
