import { Collection, Product, ResourceId } from "../types";
import {
  ImmShopifyCache,
  getAllResourcesFromImmCache,
  getResourcesFromImmCache,
  getProductsFromImmCache,
  getCollectionsFromImmCache,
} from "../cache/shopify/immCache";
import { Resource } from "../resources";

export function getAllCachedResources(
  cache: ImmShopifyCache | null
): Resource[] {
  if (!cache) return [];
  return getAllResourcesFromImmCache(cache);
}

export async function getProducts(
  productIds: string[],
  cache: ImmShopifyCache | null
): Promise<Product[]> {
  if (!cache) return [];
  return getProductsFromImmCache(productIds, cache).cachedVals;
}

export async function getCollections(
  collectionIds: string[],
  cache: ImmShopifyCache | null
): Promise<Collection[]> {
  if (!cache) return [];
  return getCollectionsFromImmCache(collectionIds, cache).cachedVals;
}

export async function getResources(
  resourceIds: ResourceId[],
  cache: ImmShopifyCache | null
): Promise<Resource[]> {
  if (!cache) return [];
  return getResourcesFromImmCache(resourceIds, cache).cachedVals;
}
