import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";

export function useCurrentUser() {
  const auth = useAuth();
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    if (!auth) {
      setCurrentUser(null);
      return;
    }
    const unsubscribe = auth.onAuthStateChanged(
      (user) => setCurrentUser(user),
      (e) => {
        console.error(e);
        setCurrentUser(null);
      }
    );
    return unsubscribe;
  }, [auth]);

  return currentUser;
}
