import { Translations } from "../../../shared/src/translations";

export interface Offer {
  uid: string | null;
  chosenId: string | null;
  triggerIds: ResourceId[];
  offeredIds: ResourceId[];
  discount: Discount;
  description: string;
  position: UpsellPosition | CustomUpsellPositioning;
  css: string;
  hasQuantitySelector: boolean;
  isOneClickCheckout: boolean;
  isActive: boolean;
  shop: string;
  dateLastModified?: Date;
}

export interface OfferCardInfo {
  uid: string;
  offeredProducts: Product[];
  discount: Discount;
  description: string;
  position: UpsellPosition | CustomUpsellPositioning;
  css: string;
  hasQuantitySelector: boolean;
  isOneClickCheckout: boolean;
  moneyFormat: string;
  translations: Translations;
}

export interface CustomUpsellPositioning {
  builderLocation: UpsellPosition;
  cartPagePosition: CustomUpsellPositioningSelector;
  nonCartPagePosition?: CustomUpsellPositioningSelector | null;
}

export interface CustomUpsellPositioningSelector {
  selector: string;
  relativeToSelector: "before" | "after";
}

export interface ResourceId {
  id: string;
  type: ResourceType;
}

export interface OfferStats {
  [key: string]: number;
  revenue: number;
  ordersCount: number;
  impressions: number;
  conversionRate: number;
}

export enum UpsellPosition {
  aboveItemsInCart = "InjectionPosition:aboveCart",
  belowItemsInCart = "InjectionPosition:belowCart",
  belowCheckout = "InjectionPosition:belowCheckout",
}

export type Discount = DiscountWithValue | DiscountWithoutValue;

interface DiscountWithoutValue {
  type: DiscountTypeWithoutValue;
}

interface DiscountWithValue {
  value: number;
  type: DiscountTypeWithValue;
}

export type DiscountTypeWithoutValue = DiscountType.compareAtPrice;
export type DiscountTypeWithValue =
  | DiscountType.valueOff
  | DiscountType.percentOff;

export enum DiscountType {
  percentOff = "DiscountType:percentOff",
  valueOff = "DiscountType:valueOff",
  compareAtPrice = "DiscountType:compareAtPrice",
}

export const ParsingKeys = {
  productName: "{{PRODUCT_NAMES}}",
};

export enum ResourceType {
  product = "ResourceType:product",
  collection = "ResourceType:collection",
}

export interface Product {
  id: string;
  name: string;
  imgSrc: string;
  type: ResourceType.product;
  variants: Variant[];
  hasOutOfStockVariants: boolean;
  nVariants: number;
  status: ProductStatus;
}

export enum ProductStatus {
  active = "ProductStatus:active",
  draft = "ProductStatus:draft",
}

export interface Collection {
  id: string;
  name: string;
  imgSrc: string;
  type: ResourceType.collection;
  nProducts: number;
  products: Product[];
}

export interface Variant {
  id: string;
  name: string;
  imgSrc: string | null;
  price: number;
  nInStock?: number;
  inventoryIsTracked: boolean;
  shouldSellWhenOutOfStock: boolean;
  compareAtPrice: number | null;
}
