import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { getFirebaseConfig } from "./config";

function initializeFirebaseIfNotAlready() {
  if (firebase.apps.length > 0) return;
  firebase.initializeApp(getFirebaseConfig());
}

export function initializeFirestore(
  useOfflinePersistence = true
): firebase.firestore.Firestore {
  initializeFirebaseIfNotAlready();
  const firestore = firebase.firestore();
  if (clientIsDev()) {
    firestore.useEmulator("localhost", 8080);
  }
  if (useOfflinePersistence) {
    firestore.enablePersistence().catch((e) => {
      console.error(e);
    });
  }
  return firestore;
}

export function initializeAuth(): firebase.auth.Auth {
  initializeFirebaseIfNotAlready();
  const auth = firebase.auth();
  if (clientIsDev()) {
    // @ts-ignore (disableWarning option has not been added to the firebase types)
    auth.useEmulator("http://localhost:9099", { disableWarnings: true });
  }
  return auth;
}

export function clientIsDev() {
  return (
    (typeof window !== "undefined" &&
      window.location.hostname === "localhost") ||
    process.env.NODE_ENV !== "production"
  );
}
