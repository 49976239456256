import { ResourceId, ResourceType } from "../../types";

const keys = {
  products: "PRODUCTS_KEY_",
  collections: "COLLECTIONS_KEY_",
};

export function getProductIdsFromKeys(keys: string[]): string[] {
  return keys.map((key) => getProductIdFromKey(key));
}

export function getProductKey(productId: string): string {
  return keys.products + productId;
}

export function getProductIdFromKey(key: string): string {
  return key.slice(keys.products.length);
}

export function getProductKeys(productIds: string[]): string[] {
  return productIds.map((productId) => getProductKey(productId));
}

export function isProductKey(key: string): boolean {
  return key.startsWith(keys.products);
}

export function isCollectionKey(key: string): boolean {
  return key.startsWith(keys.collections);
}

export function getCollectionIdsFromKeys(keys: string[]): string[] {
  return keys.map((key) => getCollectionIdFromKey(key));
}

export function getCollectionKey(collectionId: string): string {
  return keys.collections + collectionId;
}

export function getCollectionIdFromKey(key: string): string {
  return key.slice(keys.collections.length);
}

export function getCollectionKeys(collectionIds: string[]): string[] {
  return collectionIds.map((colId) => getCollectionKey(colId));
}

export function getCollectionIdsFromResourceIds(
  resourceIds: ResourceId[]
): string[] {
  return resourceIds
    .filter((resId) => resId.type === ResourceType.collection)
    .map((resId) => resId.id);
}

export function getProductIdsFromResourceIds(
  resourceIds: ResourceId[]
): string[] {
  return resourceIds
    .filter((resId) => resId.type === ResourceType.product)
    .map((resId) => resId.id);
}

export function getResourceKeys(resourceIds: ResourceId[]): string[] {
  const collectionIds = getCollectionIdsFromResourceIds(resourceIds);
  const productIds = getProductIdsFromResourceIds(resourceIds);
  return [...getCollectionKeys(collectionIds), ...getProductKeys(productIds)];
}

function getResourceIdFromKey(key: string): ResourceId {
  if (isProductKey(key)) {
    return {
      id: getProductIdFromKey(key),
      type: ResourceType.product,
    };
  }
  if (isCollectionKey(key)) {
    return {
      id: getCollectionIdFromKey(key),
      type: ResourceType.collection,
    };
  }
  return {
    id: key,
    type: ResourceType.product, // default if not found (shouldn't happen)
  };
}

export function getResourceIdsFromKeys(keys: string[]): ResourceId[] {
  return keys.map((key) => getResourceIdFromKey(key));
}
