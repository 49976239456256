export function getValueBasedOnEnv<T>({
  dev,
  staging,
  production,
}: {
  dev: T;
  staging: T;
  production: T;
}): T {
  if (process.env.NODE_ENV !== "production") {
    return dev;
  }
  if (
    process.env.APP_ENV === "staging" ||
    process.env.NEXT_PUBLIC_APP_ENV === "staging"
  ) {
    return staging;
  }
  return production;
}
