import React, { createContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import { initializeFirestore, initializeAuth } from "./initializers";
import { login } from "./auth";
import Toast from "components/Toast";
import { NextRouter, useRouter } from "next/router";
import { routeIsUnauthenticated, routes } from "pages/_app";

export const FirestoreContext = createContext<firebase.firestore.Firestore | null>(
  null
);
export const AuthContext = createContext<firebase.auth.Auth | null>(null);

export const FirebaseProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <FirestoreProvider>
      <AuthProvider>{children}</AuthProvider>
    </FirestoreProvider>
  );
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useState<firebase.auth.Auth | null>(null);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const router = useRouter();
  const token = queryToString(router.query.token);
  const shop = queryToString(router.query.shop);

  useEffect(() => {
    const auth = initializeAuth();
    setAuth(auth);
  }, []);

  useEffect(() => {
    if (!auth) return;
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (token) return;
      if (shop) {
        if (user?.uid === shop) {
          removeQueriesFromUrl(router);
        } else {
          router.push(`/auth?shop=${shop}`);
        }
      } else if (
        !user &&
        location.pathname !== routes.login &&
        !routeIsUnauthenticated({ pathname: router.pathname })
      ) {
        router.push(routes.login);
      }
    });
    return unsubscribe;
  }, [router, auth, shop, token]);

  useEffect(() => {
    if (auth === null || token === undefined) {
      return;
    }
    let cancelled = false;
    login(token, auth)
      .catch(() => {
        if (cancelled) return;
        setShowErrorToast(true);
      })
      .finally(() => {
        removeQueriesFromUrl(router);
      });
    return () => {
      cancelled = true;
    };
  }, [auth, token]);

  return (
    <AuthContext.Provider value={auth}>
      {showErrorToast && (
        <Toast
          message="Unable to login"
          isError={true}
          onDismiss={() => setShowErrorToast(false)}
        />
      )}
      {children}
    </AuthContext.Provider>
  );
};

function removeQueriesFromUrl(router: NextRouter) {
  router.replace(router.pathname);
}

function queryToString(
  query: string | string[] | undefined
): string | undefined {
  if (query === undefined) {
    return undefined;
  }
  if (typeof query === "string") {
    return query;
  }
  return query.join("");
}

const FirestoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [
    firestore,
    setFirestore,
  ] = useState<firebase.firestore.Firestore | null>(null);
  useEffect(() => {
    setFirestore(initializeFirestore());
  }, []);
  return (
    <FirestoreContext.Provider value={firestore}>
      {children}
    </FirestoreContext.Provider>
  );
};
